<template>
	<div>
		<b-row class="pb-3">
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-input-group>
					<b-form-input :value="filter" type="text" :placeholder="FormMSG(31, 'Type to Search')" @change="handleChangeFilter" />
					<b-input-group-append class="cursor-pointer">
						<b-input-group-text class="btn-search">
							<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
							<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="handleChangeFilter('')" v-else />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<b-col sm="12" md="6" lg="6" xl="6" v-if="showSelectReceiver">
				<div class="float-right">
					<b-button variant="success" size="md" @click="showDeliveries()">
						{{ this.FormMSG(219, 'Select receivers...') }}
					</b-button>
				</div>
			</b-col>
		</b-row>
		<b-row class="header-table-hierarchical">
			<b-col cols="2" class="d-flex cursor-pointer" @click="handleClickHeader('user.name')">
				<div class="w-75">{{ FormMSG(113, 'Name') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['user.name'] === 0"
							:size="16"
							:color="getColorChevrons('user.name')"
							:fill="getColorChevrons('user.name')"
						/>
						<chevron-down
							v-if="sortFilter['user.name'] === 1"
							:size="16"
							:color="getColorChevrons('user.name')"
							:fill="getColorChevrons('user.name')"
						/>
						<chevron-up
							v-if="sortFilter['user.name'] === 2"
							:size="16"
							:color="getColorChevrons('user.name')"
							:fill="getColorChevrons('user.name')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('user.departmentName')">
				<div class="w-75">{{ FormMSG(114, 'Department') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['user.departmentName'] === 0"
							:size="16"
							:color="getColorChevrons('user.departmentName')"
							:fill="getColorChevrons('user.departmentName')"
						/>
						<chevron-down
							v-if="sortFilter['user.departmentName'] === 1"
							:size="16"
							:color="getColorChevrons('user.departmentName')"
							:fill="getColorChevrons('user.departmentName')"
						/>
						<chevron-up
							v-if="sortFilter['user.departmentName'] === 2"
							:size="16"
							:color="getColorChevrons('user.departmentName')"
							:fill="getColorChevrons('user.departmentName')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer" @click="handleClickHeader('user.functionName')">
				<div class="w-75">{{ FormMSG(115, 'Fonction') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['user.functionName'] === 0"
							:size="16"
							:color="getColorChevrons('user.functionName')"
							:fill="getColorChevrons('user.functionName')"
						/>
						<chevron-down
							v-if="sortFilter['user.functionName'] === 1"
							:size="16"
							:color="getColorChevrons('user.functionName')"
							:fill="getColorChevrons('user.functionName')"
						/>
						<chevron-up
							v-if="sortFilter['user.functionName'] === 2"
							:size="16"
							:color="getColorChevrons('user.functionName')"
							:fill="getColorChevrons('user.functionName')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="2" class="d-flex cursor-pointer" @click="handleClickHeader('contractStartDate')">
				<div class="w-75">{{ FormMSG(225, 'Contract start date') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['contractStartDate'] === 0"
							:size="16"
							:color="getColorChevrons('contractStartDate')"
							:fill="getColorChevrons('contractStartDate')"
						/>
						<chevron-down
							v-if="sortFilter['contractStartDate'] === 1"
							:size="16"
							:color="getColorChevrons('contractStartDate')"
							:fill="getColorChevrons('contractStartDate')"
						/>
						<chevron-up
							v-if="sortFilter['contractStartDate'] === 2"
							:size="16"
							:color="getColorChevrons('contractStartDate')"
							:fill="getColorChevrons('contractStartDate')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="2" class="d-flex cursor-pointer" @click="handleClickHeader('contractEndDate')">
				<div class="w-75">{{ FormMSG(226, 'Contract end date') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['contractEndDate'] === 0"
							:size="16"
							:color="getColorChevrons('contractEndDate')"
							:fill="getColorChevrons('contractEndDate')"
						/>
						<chevron-down
							v-if="sortFilter['contractEndDate'] === 1"
							:size="16"
							:color="getColorChevrons('contractEndDate')"
							:fill="getColorChevrons('contractEndDate')"
						/>
						<chevron-up
							v-if="sortFilter['contractEndDate'] === 2"
							:size="16"
							:color="getColorChevrons('contractEndDate')"
							:fill="getColorChevrons('contractEndDate')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer text-center" @click="handleClickHeader('status')">
				<div class="w-75">{{ FormMSG(227, 'Status') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down v-if="sortFilter['status'] === 0" :size="16" :color="getColorChevrons('status')" :fill="getColorChevrons('status')" />
						<chevron-down v-if="sortFilter['status'] === 1" :size="16" :color="getColorChevrons('status')" :fill="getColorChevrons('status')" />
						<chevron-up v-if="sortFilter['status'] === 2" :size="16" :color="getColorChevrons('status')" :fill="getColorChevrons('status')" />
					</span>
				</div>
			</b-col>
			<b-col cols="2" class="d-flex cursor-pointer text-center" @click="handleClickHeader('currentStep')">
				<div class="w-75 text-center">{{ FormMSG(119, 'Production status') }}</div>
				<div class="w-25">
					<span class="float-right">
						<chevrons-up-down
							v-if="sortFilter['currentStep'] === 0"
							:size="16"
							:color="getColorChevrons('currentStep')"
							:fill="getColorChevrons('currentStep')"
						/>
						<chevron-down
							v-if="sortFilter['currentStep'] === 1"
							:size="16"
							:color="getColorChevrons('currentStep')"
							:fill="getColorChevrons('currentStep')"
						/>
						<chevron-up
							v-if="sortFilter['currentStep'] === 2"
							:size="16"
							:color="getColorChevrons('currentStep')"
							:fill="getColorChevrons('currentStep')"
						/>
					</span>
				</div>
			</b-col>
			<b-col cols="1" class="d-flex cursor-pointer text-center">
				<div class="w-100 text-center">{{ FormMSG(120, 'Actions') }}</div>
			</b-col>
		</b-row>
		<div class="content-table-hierarchical">
			<div v-if="documentPackages.length > 0">
				<tree-node-document-delivery
					v-for="(docPackage, index) in documentPackages"
					:key="docPackage.id"
					:document-package="docPackage"
					:parent-package="docPackage"
					:current-index="0"
					@tree-node-document-delivery:view="handleTreeNodeDocumentDeliveryView"
					@tree-node-document-delivery:sign="handleTreeNodeDocumentDeliverySign"
				/>
			</div>
			<div v-else>
				<b-row style="border: 1px solid #dfdfdf !important; margin: 0">
					<b-col cols="12" class="text-center">
						{{ FormMSG(112, 'No document packages found') }}
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import { Search, X, ChevronsUpDown, ChevronUp, ChevronDown } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { getDocumentDeliveries } from '@/cruds/document.crud';
// import { getDocumentList } from '@/modules/document-package/cruds/document.crud'
import TreeNodeDocumentDelivery from '@/modules/document-package/components/TreeNodeDocumentDelivery';
import { searchInTheTree } from '@/shared/utils';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { store } from '@/store';
import { getDocument } from '@/modules/document-package/cruds/document.crud';

const prepareResultTree = (data, stringIndex) => {
	if (stringIndex === '') return;

	let stringIndexSplited = stringIndex.split('.');
	let currIndex = parseInt(stringIndexSplited[0]);

	data[currIndex].is_selected = true;
	stringIndexSplited.shift();
	if (data[currIndex].docDeliveryChild) {
		prepareResultTree(data[currIndex].docDeliveryChild, stringIndexSplited.join('.'));
	}
};

export default {
	name: 'TableHierarchicalDocumentDelivery',

	mixins: [languageMessages],

	props: {
		documentPackageId: { type: [Number, String], required: true },
		currentDocument: {
			type: Object,
			required: false,
			default: () => {}
		},
		showSelectReceiver: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	components: {
		Search,
		X,
		ChevronsUpDown,
		TreeNodeDocumentDelivery,
		ChevronUp,
		ChevronDown
	},

	data() {
		return {
			filter: '',
			documentPackages: [],
			documentPackagesOriginal: [],
			sortFilter: {
				'user.name': 0,
				'user.departmentName': 0,
				'user.functionName': 0,
				contractStartDate: 0,
				contractEndDate: 0,
				status: 0,
				currentStep: 0
			},
			showArchivedDocument: false
		};
	},

	async mounted() {
		await this.getDocumentList();
	},

	methods: {
		handleTreeNodeDocumentDeliverySign(payload) {
			this.$emit('table-hierarchical-document-delivery:sign', payload);
		},
		async handleTreeNodeDocumentDeliveryView(payload) {
			const { parentPackage, docPackage } = payload;
			await getDocument(parentPackage.documentId).then((record) => {
				const list = record.childDocList.map((rec) => {
					const findIndexChild = parentPackage.docDeliveryChild.findIndex((dDC) => {
						return parseInt(dDC.documentId) === parseInt(rec.id);
					});

					const SRC = getFileSrc(rec, {
						imageOnly: false,
						contractId: parentPackage.contractId,
						userId: parentPackage.userId,
						requestId: store.userID()
					});

					return {
						...rec,
						src: `${SRC}?deliveryParentId=${rec.firstDeliveryParentId}`,
						status: parentPackage.docDeliveryChild[findIndexChild].status,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + rec.xid,
						ext: getFileExtension(rec.fileName)
					};
				});

				const index = list.findIndex((l) => l.id === docPackage.documentId);

				this.$previewImages({
					images: list,
					focusIndex: index === -1 ? 0 : index,
					options: {
						presentationMode: 'doc',
						showSignFileButton: false,
						showStatusFileTag: true,
						hideCommentButton: true,
						hideDisLikeButton: true,
						hideLikeDislike: true,
						hideCropButton: true,
						hideDeleteButton: true
					}
				});
			});
		},
		async handleChangeArchived(payload) {
			this.showArchivedDocument = payload;

			await this.getDocumentList();
		},
		handleChangeFilter(payload) {
			this.filter = payload;
			let filter = payload.trim().toLowerCase();

			if (filter === '') {
				this.documentPackages = this.documentPackagesOriginal;
				return;
			}

			let arr = [];
			for (let i = 0; i < this.documentPackagesOriginal.length; i++) {
				let element = this.documentPackagesOriginal[i];
				const resultSearch = searchInTheTree(element, 'documentName', filter);
				if (resultSearch !== '') {
					element.is_selected = true;
					prepareResultTree(element.docDeliveryChild, resultSearch);
					arr.push(element);
				} else {
					if (
						element.user.name.toLowerCase().indexOf(filter) > -1 ||
						element.user.firstName.toLowerCase().indexOf(filter) > -1 ||
						element.user.email.toLowerCase().indexOf(filter) > -1
					) {
						arr.push(element);
					}
				}
			}

			this.documentPackages = arr;
		},
		async getDocumentList() {
			let response = await getDocumentDeliveries(this.documentPackageId);

			const addIsSelectedChildDocList = (docDeliveryChild) => {
				let result = docDeliveryChild.map((cD) => ({
					...cD,
					is_selected: false,
					requireManagerApproval: this.currentDocument.requireManagerApproval
				}));
				return result;
			};

			response = response.map((docPackage) => ({
				...docPackage,
				docDeliveryChild: addIsSelectedChildDocList(docPackage.docDeliveryChild),
				is_selected: false,
				requireManagerApproval: this.currentDocument.requireManagerApproval
			}));

			this.documentPackages = response;
			this.documentPackagesOriginal = response;
		},
		handleClickHeader(field) {
			this.addSortFilter(field);
			this.applySortFilter(field);
		},
		addSortFilter(field) {
			let initValueSortFilter = {
				'user.name': 0,
				'user.departmentName': 0,
				'user.functionName': 0,
				status: 0,
				currentStep: 0
			};
			initValueSortFilter[field] = this.sortFilter[field];
			this.sortFilter = initValueSortFilter;

			if (this.sortFilter[field] === 2) {
				this.sortFilter[field] = 0;
			} else {
				this.sortFilter[field]++;
			}
		},
		applySortFilter(field) {
			this.documentPackages = [];

			if (this.sortFilter[field] === 1) {
				this.documentPackages = this.documentPackagesOriginal.sort((a, b) => {
					const firstCondition = eval(`a.${field}`);
					const secondCondition = eval(`b.${field}`);

					if (typeof firstCondition === 'string') {
						return firstCondition.localeCompare(secondCondition);
					} else if (typeof firstCondition === 'number') {
						return firstCondition - secondCondition;
					}
				});
			} else if (this.sortFilter[field] === 2) {
				this.documentPackages = this.documentPackagesOriginal.sort((a, b) => {
					const firstCondition = eval(`a.${field}`);
					const secondCondition = eval(`b.${field}`);

					if (typeof secondCondition === 'string') {
						return secondCondition.localeCompare(firstCondition);
					} else if (typeof secondCondition === 'number') {
						return secondCondition - firstCondition;
					}
				});
			} else if (this.sortFilter[field] === 0) {
				this.documentPackages = [...this.documentPackagesOriginal];
			}
		},
		getColorChevrons(field) {
			if (this.sortFilter[field] > 0) {
				return '#ffffff';
			}
			return '#4dbcba';
		},
		showDeliveries() {
			// copy item content into curTs structure.
			// store.state.curDocument = this.currentDocument;
			store.setCurrentDocForDeliveries(this.newDoc);
			//console.log("item id:", item.id);
			const deliveryLink = `/documents/delivery/${this.currentDocument.id.toString()} `;
			// console.log("starting:", deliveryLink);
			this.$router.push({
				path: deliveryLink
			});
		}
	}
};
</script>
