var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticStyle: { border: "1px solid #dfdfdf !important", margin: "0" },
        },
        [
          _c("b-col", { attrs: { cols: "2" } }, [
            _c(
              "span",
              {
                staticStyle: { display: "inline-block" },
                style: { marginLeft: _vm.nodeMargin + "px" },
              },
              [
                _vm.hasChildren && !_vm.documentPackage.is_selected
                  ? _c("SvgPlus", {
                      on: {
                        clicked: function ($event) {
                          return _vm.toggleChildren(_vm.documentPackage)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.hasChildren && _vm.documentPackage.is_selected
                  ? _c("SvgMinus", {
                      on: {
                        clicked: function ($event) {
                          return _vm.toggleChildren(_vm.documentPackage)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.getNameValue(_vm.documentPackage)) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.currentIndex === 0
                    ? _vm.documentPackage.user.departmentName
                    : ""
                ) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.currentIndex === 0
                    ? _vm.documentPackage.user.functionName
                    : ""
                ) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "2" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm._f("formatDate")(
                    _vm.documentPackage.contractStartDate,
                    "DD/MM/YYYY",
                    true
                  )
                ) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "2" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm._f("formatDate")(
                    _vm.documentPackage.contractEndDate,
                    "DD/MM/YYYY",
                    true
                  )
                ) +
                "\n\t\t"
            ),
          ]),
          _c("b-col", { attrs: { cols: "1" } }, [
            _c(
              "div",
              {
                staticClass: "wrap-status",
                staticStyle: { border: "none !important" },
              },
              [
                _c(
                  "div",
                  {
                    class: `status ${
                      _vm.getLabelAndClassStatus(_vm.documentPackage.status)
                        .class
                    }`,
                    staticStyle: { "font-size": "0.7rem" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.getLabelAndClassStatus(_vm.documentPackage.status)
                            .label
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _c(
              "div",
              {
                staticClass: "wrap-status",
                staticStyle: { border: "none !important" },
              },
              [
                _c(
                  "div",
                  {
                    class: `status ${
                      _vm.getLabelAndClassStatus(
                        _vm.documentPackage.senderStatus
                      ).class
                    }`,
                    staticStyle: { "font-size": "0.7rem" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.getLabelStep(
                            _vm.documentPackage.senderStatus,
                            _vm.documentPackage.currentStep,
                            _vm.parentPackage.requireManagerApproval,
                            _vm.documentPackage.status
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "1" } },
            [
              _c(
                "b-dropdown",
                {
                  staticStyle: { "border-right": "none !important" },
                  attrs: {
                    "no-caret": "",
                    dropleft: "",
                    boundary: "window",
                    variant: "none",
                    size: "sm",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [_c("more-vertical", { attrs: { size: 16 } })]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.viewDocPackage(_vm.documentPackage)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "text-color-rhapsody-in-blue" },
                        [
                          _c("Eye", { attrs: { size: 16 } }),
                          _vm._v(" " + _vm._s(_vm.FormMSG(9, "View")) + " "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.currentIndex > 0 &&
                  _vm.documentPackage.senderStatus === 3 &&
                  _vm.documentPackage.toSign
                    ? _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleClickToSign(_vm.documentPackage)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "text-color-rhapsody-in-blue" },
                            [
                              _c("file-signature", { attrs: { size: 16 } }),
                              _vm._v(
                                " " + _vm._s(_vm.FormMSG(15, "Sign")) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.currentIndex > 0 &&
                  _vm.documentPackage.senderStatus > 2 &&
                  _vm.documentPackage.fileName &&
                  _vm.documentPackage.fileName.length > 0 &&
                  _vm.documentPackage.toSign
                    ? _c("b-dropdown-item", [
                        _c(
                          "span",
                          { staticClass: "text-color-rhapsody-in-blue" },
                          [
                            _c("DownloadCloud", { attrs: { size: 16 } }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.FormMSG(115, "Certificate")) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasChildren
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.documentPackage.is_selected,
                  expression: "documentPackage.is_selected",
                },
              ],
            },
            _vm._l(_vm.documentPackage.docDeliveryChild, function (docPackage) {
              return _c("tree-node-document-delivery", {
                key: docPackage.id,
                attrs: {
                  "document-package": docPackage,
                  spacing: _vm.spacing + 30,
                  "parent-package": _vm.parentPackage,
                  "current-index": _vm.currentIndex + 1,
                },
                on: {
                  "tree-node-document-delivery:view":
                    _vm.handleTreeNodeDocumentDeliveryView,
                  "tree-node-document-delivery:sign":
                    _vm.handleTreeNodeDocumentDeliverySign,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }