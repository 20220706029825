<template>
	<div>
		<b-row style="border: 1px solid #dfdfdf !important; margin: 0">
			<b-col cols="2">
				<span style="display: inline-block" :style="{ marginLeft: nodeMargin + 'px' }">
					<SvgPlus v-if="hasChildren && !documentPackage.is_selected" @clicked="toggleChildren(documentPackage)" />
					<SvgMinus v-if="hasChildren && documentPackage.is_selected" @clicked="toggleChildren(documentPackage)" />
				</span>
				{{ getNameValue(documentPackage) }}
			</b-col>
			<b-col cols="1">
				{{ currentIndex === 0 ? documentPackage.user.departmentName : '' }}
			</b-col>
			<b-col cols="1">
				{{ currentIndex === 0 ? documentPackage.user.functionName : '' }}
			</b-col>
			<b-col cols="2">
				{{ documentPackage.contractStartDate | formatDate('DD/MM/YYYY', true) }}
			</b-col>
			<b-col cols="2">
				{{ documentPackage.contractEndDate | formatDate('DD/MM/YYYY', true) }}
			</b-col>
			<b-col cols="1">
				<div class="wrap-status" style="border: none !important">
					<div :class="`status ${getLabelAndClassStatus(documentPackage.status).class}`" style="font-size: 0.7rem">
						{{ getLabelAndClassStatus(documentPackage.status).label }}
					</div>
				</div>
			</b-col>
			<b-col cols="2" class="text-center">
				<div class="wrap-status" style="border: none !important">
					<div :class="`status ${getLabelAndClassStatus(documentPackage.senderStatus).class}`" style="font-size: 0.7rem">
						{{
							getLabelStep(
								documentPackage.senderStatus,
								documentPackage.currentStep,
								parentPackage.requireManagerApproval,
								documentPackage.status
							)
						}}
					</div>
				</div>
			</b-col>
			<b-col cols="1" class="text-center">
				<b-dropdown no-caret dropleft boundary="window" variant="none" size="sm" style="border-right: none !important">
					<template #button-content>
						<more-vertical :size="16" />
					</template>
					<b-dropdown-item @click="viewDocPackage(documentPackage)">
						<span class="text-color-rhapsody-in-blue"> <Eye :size="16" /> {{ FormMSG(9, 'View') }} </span>
					</b-dropdown-item>
					<b-dropdown-item
						v-if="currentIndex > 0 && documentPackage.senderStatus === 3 && documentPackage.toSign"
						@click="handleClickToSign(documentPackage)"
					>
						<span class="text-color-rhapsody-in-blue"> <file-signature :size="16" /> {{ FormMSG(15, 'Sign') }} </span>
					</b-dropdown-item>
					<b-dropdown-item
						v-if="
							currentIndex > 0 &&
							documentPackage.senderStatus > 2 &&
							documentPackage.fileName &&
							documentPackage.fileName.length > 0 &&
							documentPackage.toSign
						"
					>
						<span class="text-color-rhapsody-in-blue"> <DownloadCloud :size="16" /> {{ FormMSG(115, 'Certificate') }} </span>
					</b-dropdown-item>
				</b-dropdown>
			</b-col>
		</b-row>
		<div v-if="hasChildren" v-show="documentPackage.is_selected">
			<tree-node-document-delivery
				v-for="docPackage in documentPackage.docDeliveryChild"
				:key="docPackage.id"
				:document-package="docPackage"
				:spacing="spacing + 30"
				:parent-package="parentPackage"
				:current-index="currentIndex + 1"
				@tree-node-document-delivery:view="handleTreeNodeDocumentDeliveryView"
				@tree-node-document-delivery:sign="handleTreeNodeDocumentDeliverySign"
			/>
		</div>
	</div>
</template>

<script>
import { Eye, MoreVertical, FileSignature, DownloadCloud } from 'lucide-vue';
import _ from 'lodash';
import SvgPlus from '@/components/Offers/SvgPlus';
import SvgMinus from '@/components/Offers/SvgMinus';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { classDocumentStatus } from '@/shared/global-status';

export default {
	name: 'TreeNodeDocumentDelivery',

	components: {
		Eye,
		SvgPlus,
		SvgMinus,
		MoreVertical,
		FileSignature,
		DownloadCloud
	},

	mixins: [languageMessages],

	props: {
		documentPackage: { type: Object, required: true },
		spacing: { type: Number, default: 0 },
		parentPackage: { type: Object, required: true },
		currentIndex: { type: Number, required: true }
	},

	data() {
		return {
			showChildren: false
		};
	},

	computed: {
		nodeMargin() {
			return this.spacing;
		},
		hasChildren() {
			if (this.documentPackage.docDeliveryChild && this.documentPackage.docDeliveryChild.length > 0) return true;

			return false;
		},
		departmentName() {
			return store.state.myProfile.departmentName;
		},
		functionName() {
			return store.state.myProfile.functionName;
		}
	},

	methods: {
		handleTreeNodeDocumentDeliverySign(payload) {
			this.$emit('tree-node-document-delivery:sign', payload);
		},
		handleClickToSign(docPackage) {
			this.$emit('tree-node-document-delivery:sign', docPackage);
		},
		getLabelStep(senderStatus, currentStep, requireManagerApproval, documentStatus = 0) {
			const statues = [0, 1, 2];
			if (senderStatus === 5) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}
			if (currentStep === 0) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
			}

			if (currentStep === 1) {
				if (statues.includes(documentStatus)) {
					return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
				}
				if (!requireManagerApproval) {
					return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus);
				}
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(84, 'Step 1');
			}
			if (currentStep === 2) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(85, 'step 2');
			}
			if (currentStep === 3) {
				return this.GetTextFromMenuNumberAndMenuValue(1012, senderStatus) + ' ' + this.FormMSG(86, 'step 3');
			}
		},
		getLabelAndClassStatus(value) {
			return {
				label: this.GetTextFromMenuNumberAndMenuValue(1012, value),
				class: classDocumentStatus(value)
			};
		},
		getNameValue(docPackage) {
			if (this.currentIndex === 0) {
				if ((!docPackage.user.name && !docPackage.user.firstName) || (docPackage.user.name.length === 0 && docPackage.user.firstName.length === 0)) {
					return docPackage.user.email;
				} else {
					return docPackage.user.name + ' ' + docPackage.user.firstName;
				}
			} else {
				return docPackage.documentName;
			}
		},
		handleTreeNodeDocumentDeliveryView(payload) {
			this.$emit('tree-node-document-delivery:view', payload);
		},
		viewDocPackage(docPackage) {
			this.$emit('tree-node-document-delivery:view', {
				parentPackage: this.parentPackage,
				docPackage
			});
		},
		redirectTo(docPackage) {
			this.$router.push({
				path: `/document-package/${docPackage.id}?viaDocuments=1`
			});
		},
		toggleChildren(docPackage) {
			docPackage.is_selected = !docPackage.is_selected;
		}
	}
};
</script>
